<template>
  <div>
    <div class="bg-white relative" v-if="showFAQs">

      <div class="max-w-lg mx-auto p-4 pb-16 pt-10 relative">
        <div class="max-w-sm mx-auto">
          <h2 class="uppercase text-4xl font-bold text-center" style="color: #1c4ed8">
            FREQUENTLY <br />ASKED<br />
            QUESTIONS
          </h2>
          <!-- <AISearch @initAIQuestion="initAIQuestion" class="mt-6"></AISearch> -->
        </div>
        <div
          class="mt-10 flex flex-col gap-4"
        >
          <FAQItem v-for="(FAQItem, key) in FAQData" :title="FAQItem.title" :content="FAQItem.content" :key="key">
          </FAQItem>
        </div>
      </div>
    </div>
    <div class="border-t-2 border-black">
      <div class="max-w-md mx-auto p-4 relative">
        <!-- <AISearch @initAIQuestion="initAIQuestion"></AISearch> -->
        <div class="flex mt-6">
          <div class="flex-1">
            <h2 class="uppercase font-bold">Resources</h2>
            <ul class="mt-2">
              <li>
                <router-link
                  to="/disclaimer"
                  class="uppercase text-sm text-brandGrey //text-red-400"
                  >Terms and conditions</router-link
                >
              </li>
              <li>
                <router-link
                  to="/refer"
                  class="uppercase text-sm text-brandGrey //text-red-400"
                  >Become a partner</router-link>
              </li>
              <li>
                <router-link
                  to="/disclaimer"
                  class="uppercase text-sm text-brandGrey"
                  >Disclaimer</router-link
                >
              </li>
              <li>
                <a
                  href="mailto:support@numbersgame.uk"
                  class="uppercase text-sm text-brandGrey"
                  >Contact us</a
                >
              </li>
            </ul>
          </div>
          <div class="flex-1 text-right">
            <h2 class="uppercase font-bold">Navigation</h2>
            <ul class="mt-2">
              <li>
                <router-link to="/" class="uppercase text-sm text-brandGrey"
                  >Home</router-link
                >
              </li>
              <li>
                <router-link
                  to="/signup"
                  class="uppercase text-sm text-brandGrey"
                  >Signup</router-link
                >
              </li>
              <li>
                <router-link to="/why" class="uppercase text-sm text-brandGrey"
                  >How it works</router-link
                >
              </li>
              <li>
                <router-link to="/blog" class="uppercase text-sm text-brandGrey"
                  >Blog</router-link
                >
              </li>
              <!-- <li>
                <router-link to="#" class="uppercase text-sm //text-brandGrey text-red-400"
                  >Docs</router-link
                >
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer
      class="h-12 px-4 bg-gray-50 border-t-2 border-black justify-between items-center flex"
    >
      <nav class="flex items-center w-full h-12 divide-x-2 divide-black">
        <div class="flex-1">
          <p class="text-sm text-brandGrey">
            &copy; {{ year }}, Numbers Game Ltd
          </p>
        </div>
        <!-- <router-link
          to="/disclaimer"
          class="flex flex-1 items-center justify-center h-12 pr-5 uppercase text-lg text-blue-700 underline"
          >Disclaimer
          <Arrow class="ml-1" />
        </router-link> -->
        <div
          class="flex items-center justify-center h-12 text-blue-700 underline"
        >
          <a
            href="https://www.instagram.com/numbersgame.uk/"
            class="ml-5"
          >
            <img
              src="@/assets/icons/ig-icon.png"
              class="flex flex-1 items-center justify-center h-4 lg:h-6"
            />
          </a>
          <a href="https://twitter.com/numbersgameuk" class="ml-4 lg:ml-5">
            <img
              src="@/assets/icons/twitter-icon.png"
              class="flex flex-1 items-center justify-center h-4 lg:h-6 pr-1"
            />
          </a>
          <a
            href="https://www.tiktok.com/@numbers_game?lang=en"
            class="ml-3 lg:ml-5"
          >
            <img
              src="@/assets/icons/tiktok-icon.png"
              class="flex flex-1 items-center justify-center h-4 lg:h-6 pr-1"
            />
          </a>
        </div>
        <!-- <a
          href="mailto:support@numbersgame.uk"
          class="flex flex-1 items-center justify-center h-12 pl-5 uppercase text-lg text-blue-700 underline"
          >Contact
          <Arrow class="ml-1" />
        </a> -->
      </nav>
    </footer>

    <!-- <div
      class="fixed bg-black/50 flex items-center justify-center z-40 inset-0 p-2"
      v-on:click.self="hideAIOverlay"
      v-if="showAIOverlay"
    >
      <div class="border border-black p-4 bg-white w-full max-w-lg max-h-[80vh] overflow-auto flex flex-col">
        <div class="text-xxs text-gray-400 flex-1 overflow-auto"  ref="answers">
          <p v-for="(olderResponse, key) in AIQuestions" :key="key" :class="{'font-bold mb-1' : olderResponse.role == 'user', 'mb-4 pb-4 border-b border-gray-200 last-of-type:border-none last-of-type:pb-0 last-of-type:mb-0' : olderResponse.role != 'user'}">
            {{  olderResponse.content }}
          </p> -->
          <!-- <div class="flex items-center justify-center mt-4" v-if="AIQuestions.length > 5">
            <button
              v-on:click="toggleOnly5('AIResultsShort')"
              class="text-gray-400 underline text-xxs"
            >
              Show <template v-if="AIResultsShort">all</template
              ><template v-else>less</template>
            </button>
          </div> -->
        <!-- </div>
        
        <AISearch class="pt-4 mt-4 border-t border-gray-300" @initAIQuestion="initAIQuestion" :disabled="gettingResult"></AISearch>
       
      </div>
    </div> -->
  </div>
</template>

<script>
import FAQData from "./../data/FAQs"
// import Arrow from "../assets/icons/Arrow";
import FAQItem from "../components/FAQItem";
// import AISearch from "../components/AISearch";
export default {
  name: "Footer",
  components: {
    // Arrow,
    FAQItem,
    // AISearch,
  },
  data() {
    return {
      AIQuestions: [],
      latestResponse: false,
      showAIOverlay: false,
      gettingResult: false,
      overrideQuestion: '',
      FAQData: [],
      AIResultsShort: true
    };
  },
  mounted() {
    this.FAQData = FAQData;
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    showFAQs() {
      if(this.$route.path == '/why') {
        return true;
      } else if(this.$route.path == '/socials') {
        return false;
      } else {
        if(
          this.$route.path == '/signup/monthly' || 
          this.$route.path == '/signup/annual' || 
          this.$route.path == '/signup/tipsterMonthly' ||
          this.$route.path == '/signup/tipsterAnnual'
        ) {
          return false
        } else {
          return !this.$store.getters.subscriptionType;
        }
      }
    },
  },
  methods: {
    toggleOnly5(payload) {
      this[payload] = !this[payload];
    },
    hideAIOverlay() {
      this.showAIOverlay = false;
      this.AIQuestions = []
    },
    // initAIQuestion(payload) {
    //   // this.overrideQuestion = payload;
    //   this.showAIOverlay = true;
    //   document.activeElement.blur();
    //   this.AIQuestions.push({
    //     role: "user",
    //     content: payload,
    //   });
    //   setTimeout(() => {
    //     this.$refs.answers.scrollTo(0, this.$refs.answers.clientHeight);          
    //   }, 200);
    //   this.gettingResult = true;
    //   API.post("/chat", false, this.AIQuestions).then((response) => {
    //     this.latestResponse = response.data.content;
    //     this.AIQuestions.push(response.data);
    //     this.gettingResult = false;
    //     setTimeout(() => {
    //       this.$refs.answers.scrollTo(0, this.$refs.answers.clientHeight);          
    //     }, 200);
    //   });
    // },
  },
};
</script>
